import React, { useState, useContext, forwardRef } from 'react';
import { GlobalContext } from '../../../context/GlobalState';
import ProfileMainEdit from './ProfileMainEdit';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const [open, setOpen] = useState(false);

  const [ state, setState ] = useState({});
  const { editUser } = useContext(GlobalContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    editUser(state._id, state);
    setOpen(false);
    alert('Data edited successfully!');
    window.location.reload(false);
  };

  return (
    <div style={{ width: '20%'}}>
      <Button variant="text" style={{ float: 'right', color: "#032A49", border: "solid 1px #032A49" }} onClick={handleClickOpen}>
        <EditIcon />
      </Button>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowX: "clip",
            },
          },
        }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              EDIT
            </Typography>
            <Button autoFocus size='large' color="inherit" startIcon={<SaveIcon />} sx={{ width: 80 }} onClick={handleSave}>
              SAVE
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: "80%", margin: "2rem 10%" }}>
          <ProfileMainEdit state={state} setState={setState} />
        </div>
      </Dialog>
    </div>
  );
}