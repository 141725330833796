import React from 'react';
import { GlobalProvider } from '../../../context/GlobalState';
import Header from '../../Header';
import ProfileMain from './ProfileMain';
import ProfileSummary from './ProfileSummary';
import PokePetGarden from '../pokepet/PokePetGarden';
import SwipeableTextMobileStepper from '../pokepet/SwipeableTextMobileStepper';
import Sidebar from '../../Sidebar';

const Profile = () => {
    const today = new Date();
    const currentDate = today.toJSON().slice(0, 10);
    const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });

    return (
        <GlobalProvider>
            <div style={{ height: '4rem'}}></div>
            <div className='container'>
                <div className='subcontainer-25'>
                    <h4 style={{textAlign: "center", marginTop: "1rem"}}>Happy {currentDay}, {currentDate}</h4>
                    <ProfileSummary />
                    <br/>
                    <PokePetGarden />
                    {/* <SwipeableTextMobileStepper /> */}
                </div>
                <div className='subcontainer-70'>
                    <ProfileMain />
                </div>
            </div>
        </GlobalProvider>
    )
}

export default Profile
