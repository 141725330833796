import React from 'react';
import { GlobalProvider } from '../../context/GlobalState';
import UserList from '../user-management/UserList'
import MixedBag from './MixedBag';

const AdminPanel = () => {
  return (
    <GlobalProvider>
        <UserList />
        <MixedBag />
    </GlobalProvider>
  )
}

export default AdminPanel
