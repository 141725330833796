import React, { useState, useEffect } from "react";
import './App.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ExpenseTrackerApp from "./components/expense-tracker/ExpenseTrackerApp";
import PersonalExpenseTrackerApp from "./components/expense-tracker-personal/PersonalExpenseTracker";
import Login from './components/user-management/Login';
import Signup from './components/user-management/Signup';
import PinBoard from './components/PinBoard';
import NewsPocket from './components/NewsPocket';
import LunchAdmin from './components/lunch-order/LunchAdmin';
import LunchOrder from './components/lunch-order/LunchOrder';
import ServiceTicket from './components/service-center/components/ServiceTicket';
import PrivateRoutes from './components/private-route/PrivateRoutes';
import HabitTracker from './components/growth-enabler/objective-tracker/HabitTracker';
import RoleAccess from './components/private-route/RoleAccess';
import AdminPanel from './components/admin-corner/AdminPanel';
import NotFoundPage from './components/private-route/NotFoundPage';
import ServiceBoard from './components/service-center/ServiceBoard';
import Profile from './components/growth-enabler/profile/Profile';
import ManagerFeedback from './components/growth-diary/ManagerFeedback';
import GrowthTracker from './components/growth-diary/GrowthTracker';
import Sidebar from './components/Sidebar';

function App() {
  const login = window.localStorage.getItem('isLoggedIn');
  const userRole = window.localStorage.getItem('userRole');
  const [sidebarWidth, setSidebarWidth] = useState("85px"); // Default width when collapsed
  const handleSidebarToggle = (width) => {
    console.log("Sidebar toggled. Current width:", width);
    setSidebarWidth(width);
  };

  const location = useLocation();

  useEffect(() => {
    setSidebarWidth("85px"); // Reset to collapsed width on navigation
  }, [location]);

  return (

          <div className="app-container">
            {login && <Sidebar onToggle={handleSidebarToggle} />}
            <main className="main-content" style={{ marginLeft: login ? sidebarWidth : 0 }}>
              <Routes>
                <Route path="/" element={login ? <PinBoard /> : <Login />}/>
                <Route path="/signup" element={<Signup />}/>
                <Route element={<PrivateRoutes isAuth={login} />}>
                  <Route path="/not-found" element={<NotFoundPage />}/>
                  <Route path="/pin-board" element={<PinBoard />}/>
                  <Route path="/growth-enabler/profile" element={<Profile />}/>
                  <Route path="/service-center/tickets" element={<ServiceTicket />}/>
                  <Route path="/service-center/lunch-order" element={<LunchOrder />}/>
                  <Route path='/expense-tracker/team' element={<ExpenseTrackerApp isAdmin={userRole} />}/>
                  <Route path='/expense-tracker/personal' element={<PersonalExpenseTrackerApp isAdmin={userRole} />}/>
                  <Route path="/growth-enabler/habit" element={<HabitTracker />}/>
                  <Route path="/growth-enabler/diary" element={<GrowthTracker />}/>
                  <Route path="/growth-enabler/ledger-manager" element={<ManagerFeedback />}/>
                  <Route element={<RoleAccess isAdmin={userRole} />}>
                    <Route path="/service-center/lunch-admin" element={<LunchAdmin isAdmin={userRole} />}/>
                    <Route path="/service-center/service-board" element={<ServiceBoard />}/>
                    <Route path='/news-pocket' element={<NewsPocket />}/>
                    <Route path='/admin-panel' element={<AdminPanel />}/>
                  </Route>
                </Route>
              </Routes>
            </main>
          </div>

  );
}

export default App;
