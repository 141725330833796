import React from 'react';
import Header from '../Header';
import { GlobalProvider } from '../../context/GlobalState';
import AddMenu from './AddMenu';
import { ServiceProvider } from '../service-center/ServiceState';

function LunchAdmin({isAdmin}) {
  return (
    <GlobalProvider>
        <ServiceProvider>
          <AddMenu isAdmin={isAdmin} />
        </ServiceProvider>
    </GlobalProvider>

  )
}

export default LunchAdmin
