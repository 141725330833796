import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalState';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

const HabitForm = ({user, dailyHabits}) => {
    const { loggedInUser, getUserById } = useContext(GlobalContext);

    useEffect(() => {
        getUserById(user._id);
        // eslint-disable-next-line
    }, []);

    const formatDate = (date) => {
        return date.toJSON().slice(0, 10)
    }

    const [habitName, setName] = useState('');
    const [description, setDescription] = useState('');
    const [frequency, setFrequency] = useState('daily');
    const [badge, setBadge] = useState('✔️');
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();

        if ( dailyHabits.length >= 3 && frequency === 'daily' ) {
            alert('🚨 Maximum 3 daily habits at once is recommended! Please mark done one or more your ongoing habits or choose different type!');
        } else {
            const newHabit = {
                userId: loggedInUser._id,
                habitName,
                description,
                frequency,
                badge,
                startDate,
                endDate,
                progress: [],
            };
            
            const token = `${user.email}+${user.password}`
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }

            axios
            .post('/api/v1/habits', newHabit, config)
            .then((res) => {
                console.log(res.data);

                window.location.reload(false);

            })
            .catch((err) => {
                console.error(err);
            });
        };
    };

    return (
        <div className="habit-form">
            <h3>Create a new habit</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label><b>Name <i style={{fontSize: '12px'}}>(* Required)</i></b></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter a routine"
                        value={habitName}
                        onChange={(e) => {setName(e.target.value);}}
                        required
                        />
                    </Form.Group>
                <Form.Group controlId="description">
                    <Form.Label>Description *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Please describe it in more details"
                        value={description}
                        onChange={(e) => {setDescription(e.target.value);}}
                    />
                </Form.Group>
                <div className='form-control-small'>
                    <Form.Group className='form-control' controlId="frequency">
                        <Form.Label><b>Frequency *</b></Form.Label>
                        <Form.Control
                            style={{ width: '100%', height: '50%' }}
                            as="select"
                            value={frequency}
                            onChange={(e) => {setFrequency(e.target.value);}}
                            required
                            >
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='form-control' controlId="token">
                        <Form.Label><b>Badge *</b></Form.Label>
                        <Form.Control
                            style={{ width: '100%', height: '50%' }}
                            as="select"
                            value={badge}
                            onChange={(e) => {setBadge(e.target.value);}}
                            required
                            >
                            <option value="✔️">✔️</option>
                            <option value="❤️">❤️</option>
                            <option value="⭐">⭐</option>
                            <option value="💡">💡</option>
                            <option value="🖋️">🖋️</option>
                            <option value="🎨">🎨</option>
                            <option value="📚">📚</option>
                            <option value="🎶">🎶</option>
                            <option value="💻">💻</option>
                            <option value="🧪">🧪</option>
                            <option value="🔭">🔭</option>
                            <option value="💪">💪</option>
                            <option value="🏃">🏃</option>
                            <option value="🚲">🚲</option>
                            <option value="🍰">🍰</option>
                            <option value="🍳">🍳</option>
                            <option value="💐">💐</option>
                            <option value="🗺️">🗺️</option>
                            <option value="🛳️">🛳️</option>
                            <option value="🚆">🚆</option>
                            <option value="✈️">✈️</option>
                            <option value="🦄">🦄</option>
                            <option value="🐶">🐶</option>
                            <option value="🍭">🍭</option>
                            <option value="🚀">🚀</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='form-control-small'>
                    <Form.Group className='form-control' controlId="startDate">
                        <Form.Label><b>Start *</b></Form.Label>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => {setStartDate(e.target.value);}}
                            required
                        />
                    </Form.Group>
                    <Form.Group className='form-control' controlId="endDate">
                        <Form.Label><b>End</b></Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => {setEndDate(e.target.value);}}
                        />
                    </Form.Group>
                </div>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default HabitForm
