import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import "./UserList.css";

const UserList = () => {
  const { users, getUsers } = useContext(GlobalContext);
  const [selectedUser, setSelectedUser] = useState(null); // Track the selected user
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleViewMore = (user) => {
    setSelectedUser(user); // Set the selected user
    setIsSidebarOpen(true); // Open the sidebar
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false); // Close the sidebar
    setSelectedUser(null); // Clear the selected user
  };

  return (
    <div className="limiter">
      <div className="wrap-table100">
        <div className="table100">
          <table>
            <thead>
              <tr className="table100-head">
                <th className="column1">First Name</th>
                <th className="column2">Last Name</th>
                <th className="column3">Mobile</th>
                <th className="column4">Account Email</th>
                <th className="column5">Designation</th>
                <th className="column6">Team</th>
                <th className="column7">Department</th>
                <th className="column8">Type</th>
                <th className="column9"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((val, key) => (
                <tr key={key}>
                  <td className="column1"><b>{val.firstName}</b></td>
                  <td className="column2">{val.lastName}</td>
                  <td className="column3">{val.contact.mobile}</td>
                  <td className="column4">{val.email}</td>
                  <td className="column5">{val.workInfo.designation}</td>
                  <td className="column6">{val.team}</td>
                  <td className="column7">{val.workInfo.department}</td>
                  <td className="column8">{val.type}</td>
                  <td className="column9">
                    <button
                      onClick={() => handleViewMore(val)}
                      className="view-more-btn material-symbols-rounded"
                    >
                      {"more_horiz"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Sidebar for View More */}
      {isSidebarOpen && (
        <div className="detail-sidebar">
          <div className="detail-sidebar-content">
            <button className="close-btn" onClick={closeSidebar}>
              <span className="material-symbols-rounded">
                {"close"}
              </span>
            </button>
            {selectedUser && (
              <>
                <h2>User Details</h2>
                <p><strong>First Name:</strong> {selectedUser.firstName}</p>
                <p><strong>Last Name:</strong> {selectedUser.lastName}</p>
                <p><strong>Mobile:</strong> {selectedUser.contact.mobile}</p>
                <p><strong>Email:</strong> {selectedUser.email}</p>
                <p><strong>Designation:</strong> {selectedUser.workInfo.designation}</p>
                <p><strong>Department:</strong> {selectedUser.workInfo.department}</p>
                <p><strong>Team:</strong> {selectedUser.team}</p>
                <p><strong>Account:</strong> {selectedUser.type}</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;