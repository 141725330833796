import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GlobalProvider } from '../../context/GlobalState';
import Header from '../Header';

const ManagerFeedback = () => {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      const response = await axios.get('/api/v1/growth-submissions');
      setSubmissions(response.data);
    };
    fetchSubmissions();
  }, []);

  const handleFeedback = async (id, comment, thumbsUp) => {
    await axios.put(`/api/v1/growth-submissions`, { comment, thumbsUp });
    setSubmissions((prev) =>
      prev.map((submission) =>
        submission._id === id ? { ...submission, managerFeedback: { comment, thumbsUp } } : submission
      )
    );
  };

  return (
    <div style={{ width: '100%', margin: '1rem'}}>
        <h2 style={{width: '100%'}}>Growth Tracker</h2>
        <h2 style={{width: '100%'}}>Submissions</h2>
        {submissions.map((submission) => (
            <div key={submission._id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
            <h3>{submission.name}</h3>
            <p><strong>Result:</strong> {submission.result}</p>
            <p><strong>Learning:</strong> {submission.learning}</p>
            <div>
                {submission.managerFeedback ? (
                <div>
                    <p><strong>Feedback:</strong> {submission.managerFeedback.comment}</p>
                    <p><strong>Thumbs Up:</strong> {submission.managerFeedback.thumbsUp ? 'Yes' : 'No'}</p>
                </div>
                ) : (
                <div>
                    <textarea
                    placeholder="Comment"
                    onChange={(e) =>
                        handleFeedback(submission._id, e.target.value, submission.managerFeedback?.thumbsUp || false)
                    }
                    />
                    <button onClick={() => handleFeedback(submission._id, 'Great work!', true)}>Thumbs Up</button>
                </div>
                )}
            </div>
            </div>
        ))}
    </div>
  );
};

export default ManagerFeedback;