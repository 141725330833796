import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { TaskContext } from "../TaskState";
import { timeFormatted } from '../../../utils/DateFormat';

export default function TaskEdit({task, members}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [ summary, setSummary] = useState(task.desc);
  const [ importance, setImportance] = useState(task.importance);
  const [ doer, setDoer] = useState(task.doer);
  const [ doerId, setDoerId] = useState(task.doerId);
  const [ due, setDue] = useState(timeFormatted(task.dueDate));

  const { editToDo } = useContext(TaskContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSubmit = e => {
    e.preventDefault();
    const editedToDo = {
      desc: summary,
      importance: importance,
      dueDate: due,
      doer: doer,
      doerId: doerId,
    }

    editToDo(task._id, editedToDo);
    alert("Changes saved successfully!")

    window.location.reload(false);
  }

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}
        sx = {{
            color: '#032A49',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            height: 20,
            border: '1px solid #032A49',
            borderRadius: 10,
            marginTop: 0.5,
            minWidth: 'fit-content',
            ':hover': {
              backgroundColor: '#032A49',
              color: '#fff',
            }
        }}>
        <EditIcon fontSize='6'/>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2, fontSize: 12, width: '100%', minWidth: 320 }}>
            <form onSubmit={onSubmit}>
                <label htmlFor='text'><b>Task Details</b></label>
                <input style={{fontSize: 12}} type='text' id='task-name' value={summary} onChange={(e) => setSummary(e.target.value)}
                    placeholder={task.desc} />
                <label htmlFor='text'><b>Person In Charge</b></label>
                <select id="doer" value={doer} onChange={(e) => {
                  setDoer(e.target.value);
                  setDoerId( members.filter( i => i[0] === e.target.value )[0][1] );
                  }}>
                    {members.map( i => <option>{i[0]}</option>)}
                </select>
                <label htmlFor='text'><b>Importance</b></label>
                <select className="minimal" id="importance" value={importance} onChange={(e) => setImportance(e.target.value)}>
                    <option key="high">High</option>
                    <option key="medium">Medium</option>
                    <option key="low">Low</option>
                </select>
                <label htmlFor='date'><b>Due Date</b></label>
                <input style={{fontSize: 12}} type='date' id='due-date' defaultValue={due} onChange={(e) => setDue(e.target.value)}/>
                <button className='btn' style={{fontSize: 12}} type='submit'>Save changes</button>
            </form>
        </Typography>
      </Popover>
    </div>
  );
}