import React from 'react'

const MixedBag = () => {
  return (
    <div>
      Under construction!!!
    </div>
  )
}

export default MixedBag
