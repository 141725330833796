import React, { useReducer, useContext, useEffect } from 'react';
import axios from 'axios';
import { GlobalProvider, GlobalContext } from '../../context/GlobalState';


  // Reducer function
const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'RESET_FORM':
      return {
        accomplishmentChoice: '',
        details: '',
        nextWeekObjectives: '',
        newLearnings: '',
      };
    default:
      return state;
  }
};

const EmployeeSubmission = ({user}) => {

  // Initial form state
  const initialState = {
    accomplishmentChoice: '',
    details: '',
    nextWeekObjectives: '',
    newLearnings: '',
  };

  // Use the reducer
  const [formState, dispatch] = useReducer(formReducer, initialState);

  // Handle form submission
  const handleSubmit = async () => {
    await axios.post('/api/v1/growth-submissions', {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      org: user.org,
      team: user.team,
      ...formState, // Spread the form state to include all other fields
    });
    alert('Submission sent!');
    dispatch({ type: 'RESET_FORM' }); // Reset form after submission
  };
  
  return (
        <div style={{margin: '0 2rem', display: 'flex', flexDirection: 'column'}}>
          <h2 style={{width: '100%'}}>Weekly Submission</h2>

          <div>
            <p>1. What do you think about your accomplishments during the week?</p>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <label style={{marginBottom: '10px'}}>
                <input
                  type="radio"
                  value="I accomplished major objectives this week, meeting deadlines and maintaining high quality standards, which significantly contributed to the team's goals!"
                  checked={
                    formState.accomplishmentChoice ===
                    "I accomplished major objectives this week, meeting deadlines and maintaining high quality standards, which significantly contributed to the team's goals!"
                  }
                  onChange={(e) =>
                    dispatch({ type: 'SET_FIELD', field: 'accomplishmentChoice', value: e.target.value })
                  }
                />
                I accomplished major objectives this week, meeting deadlines and maintaining high quality standards, which significantly contributed to the team's goals!
              </label>
              <label style={{marginBottom: '10px'}}>
                <input
                  type="radio"
                  value="Although some tasks were delayed, the quality of deliverables remained high."
                  checked={formState.accomplishmentChoice === "Although some tasks were delayed, the quality of deliverables remained high."}
                  onChange={(e) =>
                    dispatch({ type: 'SET_FIELD', field: 'accomplishmentChoice', value: e.target.value })
                  }
                />
                Although some tasks were delayed, the quality of deliverables remained high.
              </label>
              <label style={{marginBottom: '10px'}}>
                <input
                  type="radio"
                  value="All tasks were delivered on time and met acceptable standards."
                  checked={formState.accomplishmentChoice === "All tasks were delivered on time and met acceptable standards."}
                  onChange={(e) =>
                    dispatch({ type: 'SET_FIELD', field: 'accomplishmentChoice', value: e.target.value })
                  }
                />
                All tasks were delivered on time and met acceptable standards.
              </label>
              <label style={{marginBottom: '10px'}}>
                <input
                  type="radio"
                  value="Many tasks were delayed, and several deliverables were not accepted."
                  checked={formState.accomplishmentChoice === "Many tasks were delayed, and several deliverables were not accepted."}
                  onChange={(e) =>
                    dispatch({ type: 'SET_FIELD', field: 'accomplishmentChoice', value: e.target.value })
                  }
                />
                Many tasks were delayed, and several deliverables were not accepted.
              </label>
            </div>
          </div>

          <div>
            <p>2. Could you detail your accomplishments and challenges? Specifics help us tailor support for you.</p>
            <textarea
              placeholder="Provide details here..."
              value={formState.details}
              onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'details', value: e.target.value })}
            />
          </div>

          <div>
            <p>3. What are your main objectives for the next week?</p>
            <textarea
              placeholder="Provide your objectives for next week..."
              value={formState.nextWeekObjectives}
              onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'nextWeekObjectives', value: e.target.value })}
            />
          </div>

          <div>
            <p>4. Can you share any new learnings for you during this week?</p>
            <textarea
              placeholder="What did you learn this week?"
              value={formState.newLearnings}
              onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'newLearnings', value: e.target.value })}
            />
          </div>

          <button className='btn' style={{width: '20%', margin: '2rem auto'}} onClick={handleSubmit}>Submit</button>
        </div>
  );
};

export default EmployeeSubmission;