import React from 'react';
import '../../App.css';
import { GlobalProvider } from '../../context/GlobalState';

const NotFoundPage = () => {
  return (
    <GlobalProvider>
        <div className="content">
            <div className="planet">
            <div className="ring"></div>
                <div className="cover-ring"></div>
            <div className="spots">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
        
            </div>
            </div>
            <p>This Orb Can't Be Reached</p>
        </div>
    </GlobalProvider>
  )
}

export default NotFoundPage
