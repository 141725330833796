import React, { useEffect, useRef } from 'react';
import Header from './Header';
import { GlobalProvider } from '../context/GlobalState';
import RollingText from './RollingText';
import CalHeatmap from 'cal-heatmap';
import 'cal-heatmap/cal-heatmap.css';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import { timeFormatted } from '../utils/DateFormat';

function PinBoard() {

  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);;
  const arr = userObj.firstName.toUpperCase().split('');

  const today = new Date();
  const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });

  const calHeatmapContainer = useRef(null);
  const calInstance = useRef(null);

  useEffect(() => {
    // Initialize CalHeatmap
    const cal = new CalHeatmap();

    const mockData = [
      { date: "2025-01-01", status: "on" },
      { date: "2025-01-02", status: "on" },
      { date: "2025-01-03", status: "WFH" },
      { date: "2025-01-04", status: "on" },
      { date: "2025-01-05", status: "off" },
      { date: "2025-01-06", status: "on" },
      { date: "2025-01-07", status: "on" },
      { date: "2025-01-08", status: "off" },
      { date: "2025-01-09", status: "on" },
      { date: "2025-01-10", status: "on" }
    ]

    calInstance.current = cal;

    try {
      cal.paint(
        {
          itemSelector: calHeatmapContainer.current,
          data: {
            source: mockData,
            type: 'json',
            x: 'date',
            y: (d) => {
              if (d["status"] === "off") return 0;  // Day off
              if (d["status"] === "on") return 1;   // On-site work
              if (d["status"] === "WFH") return 2;  // Work From Home
            }
          },
          date: { start: new Date('2025-01-01') },
          range: 12,
          scale: {
            color: {
              type: 'threshold',
              range: ['#fff', '#000', '#4dd05a', '#FFC107'],
              domain: [0, 1, 2],
            },
          },
          domain: {
            type: 'month',
            gutter: 4,
            label: { text: 'MMM', textAlign: 'start', position: 'top' },
          },
          subDomain: { type: 'day', radius: 2, width: 11, height: 11, gutter: 4 },
        },
        [
          [Tooltip, { text: (date, value) => 
            `${timeFormatted(date).split('T')[0]}: ${
            value === 0 ? 'Off' : value === 1 ? 'On-site' : 'WFH'
            }` }],
          // [LegendLite, { includeBlank: true }],
          [
            CalendarLabel, {
              width: 30,
              textAlign: 'start',
              text: () => ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              padding: [25, 0, 0, 0],
            },
          ],
        ]
      );
    } catch (err) {
      console.error("CalHeatmap initialization error:", err);
    }

    // cal.paint(
    //   {
    //     data: {
    //       source: '../fixtures/seattle-weather.csv',
    //       type: 'csv',
    //       x: 'date',
    //       y: d => +d['temp_max'],
    //       groupY: 'max',
    //     },
    //     date: { start: new Date('2012-01-01') },
    //     range: 12,
    //     scale: {
    //       color: {
    //         type: 'threshold',
    //         range: ['#14432a', '#166b34', '#37a446', '#4dd05a'],
    //         domain: [10, 20, 30],
    //       },
    //     },
    //     domain: {
    //       type: 'month',
    //       gutter: 4,
    //       label: { text: 'MMM', textAlign: 'start', position: 'top' },
    //     },
    //     subDomain: { type: 'ghDay', radius: 2, width: 11, height: 11, gutter: 4 },
    //     itemSelector: '#ex-ghDay',
    //   },
    //   [
    //     [
    //       Tooltip,
    //       {
    //         text: function (date, value, dayjsDate) {
    //           return (
    //             (value ? value : 'No') +
    //             ' contributions on ' +
    //             dayjsDate.format('dddd, MMMM D, YYYY')
    //           );
    //         },
    //       },
    //     ],
    //     [
    //       LegendLite,
    //       {
    //         includeBlank: true,
    //         itemSelector: '#ex-ghDay-legend',
    //         radius: 2,
    //         width: 11,
    //         height: 11,
    //         gutter: 4,
    //       },
    //     ],
    //     [
    //       CalendarLabel,
    //       {
    //         width: 30,
    //         textAlign: 'start',
    //         text: () => dayjs.weekdaysShort().map((d, i) => (i % 2 == 0 ? '' : d)),
    //         padding: [25, 0, 0, 0],
    //       },
    //     ],
    //   ]
    // );

    // Cleanup when the component unmounts
    return () => {
      cal.destroy();
    };
  }, []);

  return (
    <GlobalProvider>
      {/* <div style={{ height: '5rem'}}></div> */}
      {/* <Header /> */}
      <h1 style={{textAlign: 'center', color: '#032A49', letterSpacing: '2px'}}>
        <br/>
        Happy {currentDay}, {userObj.firstName}!
        <br/>
        🌟🌟🌟
        <br/>
        <br/>
        Your Amazing Traits Will Help You Shine Today
      </h1>
      <h1 className='h1-text'>
        {arr.map(char => (<RollingText key = {Math.floor(Math.random() * 100000000)} firstNameChar = {char}/>))}
      </h1>

      <div className='cal-heatmap-container'>
        <div ref={calHeatmapContainer} style={{ margin: '20px auto' }}></div>
        <div style={{ display: 'inline-flex' }}>
          <button className='btn' style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => calInstance.current.previous()}>←</button>
          <button className='btn' style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => calInstance.current.next()}>→</button>
        </div>
      </div>


    </GlobalProvider>
  )
}

export default PinBoard
