import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Avatar } from "antd";
import { timeFormatted } from '../../../utils/DateFormat';
import TaskEdit from "./TaskEdit";
import Comment from "./Comment";

const Container = styled.div`
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 8px;
  color: #032A49;
  margin-bottom: 1rem;
  min-height: 90px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${(props) => bgcolorChange(props)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextContent = styled.div``;

const Icons = styled.div`
  display: flex;
  justify-content: end;
  padding: 2px;
`;
function bgcolorChange(props) {
  return props.isdragging === 'true'
    ? "#FFC107"
    : props.isDraggable
    ? props.isBacklog
      ? "#F2D7D5"
      : "#DCDCDC"
    : props.isBacklog
    ? "#F2D7D5"
    : "#C4C5DA";
}


const currentDate = new Date().toJSON();
const dueDatePassed = (dueDate, today) => {
  // let result = dueDate - today ? false : true;
  let date1 = new Date(dueDate);
  let date2 = new Date(today);
  let result = ((date1.getTime() - date2.getTime()))/(1000*60*60*24);
  return result
}

const ServiceTask = ({ task, index, members }) => {
  return (
    <Draggable draggableId={`${task._id}`} key={task._id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isdragging={(snapshot.isDragging).toString()}
          
        >
          {/* <div style={{ display: "flex", justifyContent: "start", padding: 2 }}>
            <span>
              <small>
                #{task._id}
                {"  "}
              </small>
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "start", padding: 2 }}>
            <span>
              <small>
                &#128100; {task.requester}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#128467; <b style={
                  dueDatePassed(task.dueDate, currentDate) < 0 && !task.completed ? {backgroundColor: "#D2042D", color: '#fff'} :
                  dueDatePassed(task.dueDate, currentDate) < 7 && !task.completed ? {backgroundColor: "#DC4C64", color: '#fff'} :
                  dueDatePassed(task.dueDate, currentDate) < 14 && !task.completed ? {backgroundColor: "#FFC107", color: '#fff'} : {}
              }>{
                  timeFormatted(task.dueDate)
              }</b></small>
            </span>
            <span className={ task.importance === 'High' ? 'high-dot' : task.importance === 'Medium' ? 'medium-dot' : 'low-dot' }>
              <b style={task.completed ? { opacity: 1, color: '#fff' } : { opacity: 0 }}>&nbsp;&#10004;</b>
            </span>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", padding: '0.5rem', paddingTop: '1rem' }}
          >
            <TextContent>
              {task.desc}
            </TextContent>
          </div>
          <Icons style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%', display: 'flex' }}>
              <Comment key={task._id} task={task}/>
              {!task.completed ? <TaskEdit key={task._id} task={task} members={members}/> : <span style={{ width: '25%' }}></span>}
            </div>
            <div>
              <span style={{ color: "#032A49", paddingRight: "0.5rem" }}>
                <b><small>
                  {task.doer ? task.doer : '- - - - -'}
                </small></b>
              </span>
              <Avatar
                src={"https://joesch.moe/api/v1/male/random?" + task.doerId}
                // src={"https://ui-avatars.com/api/?background=416129&color=fff&name=" + task.doer}
              />
            </div>
          </Icons>
          {provided.placeholder}
        </Container>
      )}
    </Draggable>
  )
}

export default ServiceTask
