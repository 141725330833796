import React from 'react';
import '../../App.css';
import Header from '../Header';
import { GlobalProvider } from '../../context/GlobalState';
import EmployeeSubmission from './EmployeeSubmission';
import ManagerFeedback from './ManagerFeedback';
import Sidebar from '../Sidebar';

function GrowthTracker() {
  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);
  
  return (
    <GlobalProvider>
        <div style={{ height: '4rem'}}></div>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <ManagerFeedback />
            <div className='accordion'>
                <details>
                <summary style={{margin: '1rem', fontSize: '1.25rem', padding: '0.3rem 0.7rem'}}>+</summary>
                    <EmployeeSubmission user={userObj}/>
                </details>
            </div>
        </div>
    </GlobalProvider>
  )
}

export default GrowthTracker
