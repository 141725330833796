import React from 'react';
import '../App.css';
import Header from './Header';
import { GlobalProvider } from '../context/GlobalState';

async function searchNews(q) {
  q = encodeURIComponent(q);
  const response = await fetch(`https://google-web-search1.p.rapidapi.com/?query=${q}&limit=100&related_keywords=true`, {
    "method": "GET",
    "headers": {
      "x-rapidapi-host": "google-web-search1.p.rapidapi.com",
      "x-rapidapi-key": "e7b0796133msh5e8ea077f4f565dp1ee8dbjsn6e4ebd5938fb",
    }
  });
  const body = await response.json();
  console.log(body.results);
  return body.results;
}
function NewsPocket() {
  const [query, setQuery] = React.useState("");
  const [list, setList] = React.useState(null);
  // searchNews("người lao động đơn phương chấm dứt hợp đồng").then(setList);

  const search = (e) => {
    e.preventDefault();
    searchNews(query).then(setList);
  };
  return (
    <GlobalProvider>
        <div style={{ height: '4rem'}}></div>
        <div className="news-app">
        <form className="news-form" onSubmit={search}>
            <input
            autoFocus
            value={query}
            onChange={e => setQuery(e.target.value)}
            />
            <button className='news-button'>Get News</button>
        </form>
        {!list
            ? null
            : list.length === 0
            ? <p><i>No results</i></p>
            : <ul style={{padding: 0}}>
                {list.map((item, i) => (
                <Item key={i} item={item} />
                ))}
            </ul>
        }
        </div>
    </GlobalProvider>
  );
}
function Item({ item }) {
  // const separateWords = s => s.replace(/[A-Z][a-z]+/g, '$& ').trim();
  // const formatDate = s => new Date(s).toLocaleDateString(undefined, { dateStyle: 'long' });
  return (
    <li className="news-item">
      {/* {item.image &&
        <img className="news-thumbnail"
          alt=""
          src={item.image?.thumbnail?.contentUrl}
        />
      } */}
      <h2 className="news-title">
        <a href={item.url}>{item.title}</a>
      </h2>
      <p className="news-description">
        {item.description}
      </p>
      {/* <div className="news-meta">
        <span>{formatDate(item.datePublished)}</span>
        <span className="news-provider">
          {item.provider[0].image?.thumbnail &&
            <img className="news-provider-thumbnail"
              alt=""
              src={item.provider[0].image.thumbnail.contentUrl + '&w=16&h=16'}
            />
          }
          {item.provider[0].name}
        </span>
        {item.category &&
          <span>{separateWords(item.category)}</span>
        }
      </div> */}
    </li>
  );
}

export default NewsPocket

