import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HabitCalendarItem from './HabitCalendarItem'
import HabitForm from './HabitForm';

const HabitCalendar = ({user}) => {

    const [dailyHabits, setDailyHabit] = useState([]);
    const [weeklyHabits, setWeeklyHabit] = useState([]);
    const [monthlyHabits, setMonthlyHabit] = useState([]);

    useEffect(() => {
        const token = `${user.email}+${user.password}`
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        axios
        .get('/api/v1/habits', config)
        .then((res) => {
            const arr = res.data.data;
            // const convertedArr = []
            // arr.forEach( d => convertedArr.push(timeFormatted(d)) );
            setDailyHabit(arr.filter( item => item.userId === user._id && item.status === 'Ongoing' && item.frequency === 'daily' ));
            setWeeklyHabit(arr.filter( item => item.userId === user._id && item.status === 'Ongoing' && item.frequency === 'weekly' ));
            setMonthlyHabit(arr.filter( item => item.userId === user._id && item.status === 'Ongoing' && item.frequency === 'monthly' ));
        })
        .catch((err) => {
            console.error(err);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{width: '100%', padding: '1rem 2rem'}}>
            {/* <div style={{ height: '4rem'}}></div> */}
            <div className='accordion'>
                <details>
                <summary style={{margin: '0.7rem 1rem 1.5rem', fontSize: '1.25rem', padding: '0.3rem 0.7rem'}}>+</summary>
                    <HabitForm user={user} dailyHabits={dailyHabits}/>
                </details>
            </div>
            <h2>Habit Tracker</h2>
            <details open>
                <summary style={{ fontSize: '1.5rem', color: '#032A49' }}> Today</summary>
                <div className='habit-calendar'>
                    {dailyHabits.map(habit => <HabitCalendarItem key={habit._id} habit={habit} user={user} />)}
                </div>
            </details>
            <br/>
            <details open>
                <summary style={{ fontSize: '1.5rem', color: '#032A49' }}> This Week</summary>
                <div className='habit-calendar'>
                    {weeklyHabits.map(habit => <HabitCalendarItem key={habit._id} habit={habit} user={user} />)}
                </div>
            </details>
            <br/>
            <details open>
                <summary style={{ fontSize: '1.5rem', color: '#032A49' }}> This Month</summary>
                <div className='habit-calendar'>
                    {monthlyHabits.map(habit => <HabitCalendarItem key={habit._id} habit={habit} user={user} />)}
                </div>
            </details>
        </div>
    )
}

export default HabitCalendar
