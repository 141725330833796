import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import loginimgNew from "../../assets/loginimgNew.jpg";
import { EnvelopeIcon } from '@heroicons/react/16/solid';
import { LockClosedIcon } from '@heroicons/react/16/solid';

function Login() {
  
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    async function submit(e){
      e.preventDefault();

      try{

          await axios.post("/api/v1/users/login",{
              email,password
          })
          .then(res => {
                if (res.data.data.type === 'none') {
                    setErrorMessage('Your registration is being reviewed. Please get back later.')
                } else if (res.data.log === "exist"){
                    window.localStorage.setItem('isLoggedIn', true);
                    window.localStorage.setItem('userRole', res.data.data.type);
                    window.localStorage.setItem('user', JSON.stringify(res.data.data));
                    window.location.reload(false);
                } else if (res.data.log === "wrong-pwd") {
                    setErrorMessage('Incorrect password')
                }
          })
          .catch(e=>{
              setErrorMessage("Please sign up!");
              console.log(e);
          })

      }
      catch(e){
          console.log(e);

      }

  }

  const closeErrorMessage = () => {
    setErrorMessage("");
  };

  return (

    <div style={{ display: 'flex', flexDirection: 'row' }}>
    {/* <div className="flex flex-row min-h-screen bg-gray-100 items-center justify-center"> */}

        <div><img src={loginimgNew} alt="main-img" className="img-container"/></div>
        {/* <img src={loginimg} alt="main-img" className="w-1/4 h-1/2 object-cover hidden lg:block"/> */}
        
        <div className="login-container">
        {/* <div className="w-full max-w-md p-6 bg-white border-2 border-[#416129] rounded-xl shadow-md"> */}
            <h1 className="text-3xl font-bold text-gray-800 text-center mb-6 pb-4">Login</h1>

            <form action="POST">
                {/* <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="&#x1F4E7; | Email"  /> */}
            
                <label htmlFor="email" className="flex items-center text-sm font-medium text-gray-900">
                    <EnvelopeIcon style={{ position: 'relative', top: '0.1rem', width: '1rem', height: '1rem', marginRight: '0.5rem'}}/>
                    Email
                </label>
                <div className="mt-2">
                    <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
                        <input
                            id="email"
                            type="email" 
                            name="email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder="You@example.com" 
                            className="w-full border-none bg-transparent outline-none placeholder:italic focus:outline-none"
                        />
                    </div>
                </div>

                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                    <LockClosedIcon style={{ position: 'relative', top: '0.1rem', width: '1rem', height: '1rem', marginRight: '0.5rem'}}></LockClosedIcon>
                    Password
                </label>
                <div className="password-wrapper">
                    <input 
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password" 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Password" 
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                        aria-label={showPassword ? "Hide password" : "Show password"}
                    >
                        <span className="material-symbols-rounded">
                            {showPassword ? "visibility_off" : "visibility"}
                        </span>
                    </button>
                </div>
                <button className="btn" type="submit" onClick={submit}>Log me in</button>

            </form>

            <br />

            <Link to="/signup">I'm New, Sign Me Up</Link>

        </div>
        {errorMessage && (
            <div className="popup">
            <div className="popup-content">
                <span className="material-symbols-rounded" style={{ fontSize: '2rem', color: 'red' }}>
                    {"error"}
                </span>
                <p>{errorMessage}</p>
                <button onClick={closeErrorMessage}>Try again</button>
            </div>
            </div>
        )}
    </div>
)
}

export default Login
