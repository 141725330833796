import '../../App.css';
import Header from '../Header';
import PersonalBalance from '../expense-tracker-personal/PersonalBalance';
import PersonalIncomeExpenses from '../expense-tracker-personal/PersonalIncomeExpenses';
import PersonalCatSummary from '../expense-tracker-personal/PersonalCatSummary';
import PersonalMonthlySummary from '../expense-tracker-personal/PersonalMonthlySummary';
import TransactionListByUser from './TransactionListByUser';
import AddTransation from '../expense-tracker/AddTransation';
import SearchBar from '../expense-tracker/SearchBar';
import { GlobalProvider } from '../../context/GlobalState';
import Sidebar from '../Sidebar';

function PersonalExpenseTrackerApp({isAdmin}) {
    const userInfo = window.localStorage.getItem('user');
    const userObj = JSON.parse(userInfo);
    const userFullname = `${userObj.firstName} ${userObj.lastName}`;

    return (
        <GlobalProvider>
            <div style={{ height: '4rem'}}></div>
            <div className='container' style={{justifyContent: 'space-between'}}>
                <h2>
                    Personal Ledger &nbsp;&nbsp;&nbsp;&nbsp; &#12297;
                    <br/><i style={{textTransform: 'none', fontSize: '1.5rem'}}>{userFullname}</i>
                </h2>
                <SearchBar />
            </div>
            <div className='container'>
                <div className='subcontainer'>
                    <PersonalBalance />
                    <PersonalIncomeExpenses />
                    <PersonalCatSummary />
                </div>
                <div className='subcontainer'>
                    <PersonalMonthlySummary />
                </div>
                <div className='subcontainer'>
                    <AddTransation />
                </div>
            </div>
            <div className='transaction-list'>
                <TransactionListByUser isAdmin={isAdmin} />
            </div>
        </GlobalProvider>
    );
}

export default PersonalExpenseTrackerApp;
