import React, { useState, useEffect, useContext } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import ServiceColumn from "./ServiceColumn";
import { TaskContext } from "../TaskState";
import { GlobalContext } from '../../../context/GlobalState';

const ServiceKanban = ({ toDosCompleted, toDosIncomplete }) => {
  const [backlog, setBacklog] = useState([]);
  const [doing, setDoing] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const [members, setMembers] = useState([]);

  // useEffect(() => {
  //   fetch("https://jsonplaceholder.typicode.com/todos")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setCompleted(json.filter((task) => task.completed));
  //       setIncomplete(json.filter((task) => !task.completed));
  //     });
  // }, []);

  const { toDos, getToDos, editToDo } = useContext(TaskContext);
  const { users, getUsers } = useContext(GlobalContext);

  useEffect(() => {
      getToDos();
      setBacklog(toDos.filter(task => !task.doer));
      setIncomplete(toDos.filter(task => (task.doer && !task.completed && !task.doing)));
      setDoing(toDos.filter(task => (task.doer && !task.completed && task.doing)));
      setCompleted(toDos.filter(task => task.completed));

      getUsers();
      setMembers(users.map(user => [`${user.firstName} ${user.lastName}`, user._id]))
      // eslint-disable-next-line
  }, [toDos.length > 0 && users.length > 0]);

  const userInfo = window.localStorage.getItem('user');
  const userObj = JSON.parse(userInfo);

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (source.droppableId === null || !destination.droppableId === null) {
      return;
    } else if (source.droppableId === destination.droppableId) {
      return;
    }

    //REMOVE FROM SOURCE ARRAY

    // if (source.droppableId === '2') {
    //   setDoing(removeItemById(draggableId, doing));
    // }

    if (source.droppableId === '3') {
      setCompleted(removeItemById(draggableId, completed));
    } else if (source.droppableId === '2') {
      setDoing(removeItemById(draggableId, doing));
    } else if (source.droppableId === '1') {
      setIncomplete(removeItemById(draggableId, incomplete));
    } else {
      setBacklog(removeItemById(draggableId, backlog));
    }

    // GET ITEM

    const task = findItemById(draggableId, [...backlog, ...doing, ...incomplete, ...completed]);

    //ADD ITEM
    if (destination.droppableId === '3') {
      editToDo(task._id, {completed: true, completedAt: Date.now()});
      setCompleted([{ ...task, completed: !task.completed }, ...completed]);
    } else if (destination.droppableId === '2') {
      editToDo(task._id, {doing: true, completed: false, completedAt: null});
      setDoing([{ ...task, doing: true, completed: false }, ...doing]);
    } else if (destination.droppableId === '1') {
      if (!task.doer) {
        editToDo(task._id, {
          doer: `${userObj.firstName} ${userObj.lastName}`, doerId: userObj._id})
      } else {
        editToDo(task._id, {doing: false, completed: false});
      }
      setIncomplete([{ ...task, doing: false, completed: false, doer: `${userObj.firstName} ${userObj.lastName}` }, ...incomplete]);
    }
  };

  function findItemById(id, array) {
    return array.find((item) => item._id == id);
  }

  function removeItemById(id, array) {
    return array.filter((item) => item._id != id);
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <h2 style={{ textAlign: "left", width: '100%', margin: '1rem auto', textAlign: 'center' }}>SERVICE DELIVERY BOARD</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <ServiceColumn title={"REQUEST BACKLOG"} tasks={backlog} members={members} id={"0"} />
        <ServiceColumn title={"TO DO"} tasks={incomplete} members={members} id={"1"} />
        <ServiceColumn title={"DOING"} tasks={doing} members={members} id={"2"} />
        <ServiceColumn title={"DONE"} tasks={completed} members={members} id={"3"} />
      </div>
    </DragDropContext>
  )
}

export default ServiceKanban
